<template>
  <div class="pa-2 mx-lg-auto">
    <v-dialog
      persistent
      v-model="dialog"
      width="500"
      @keydown.esc="$emit('closed', true)"
      @keydown.enter="submit(wallet)"
    >
      <v-card class="text-center rounded-lg" v-if="!keplr">
        <v-card-title class="h2 lighten-2">Send Transaction </v-card-title>

        <v-card-text>
          Set the amount of gas or the fee you are willing to pay.
        </v-card-text>
        <v-row class="ma-2">
          <v-col>
            <p class="caption">Gas maximum:</p>

            <v-text-field
              label="Amount"
              type="number"
              v-model="gas"
              :rules="[rules.price]"
              append-icon="mdi-fuel"
            ></v-text-field>
          </v-col>

          <v-col>
            <p class="caption">Fee maximum:</p>

            <v-text-field
              label="Amount"
              type="number"
              v-model="amount"
              :rules="[rules.price]"
              append-icon="$vuetify.icons.custom"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-divider></v-divider>
        <v-alert dense class="ma-2" type="warning" v-if="error"
          >Oops, the result is: {{ error.rawLog }}</v-alert
        >
        <v-alert dense class="ma-2" type="success" v-else-if="sent"
          >The transaction is sent successfully</v-alert
        >
        <v-card-actions>
          <v-btn text @click="$emit('closed', true)"> Close </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="submit(wallet)"
            ><span v-if="error">re</span>Send
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card class="text-center rounded-lg" v-else>
   
        <v-card-title class="h2 lighten-2">Send Transaction </v-card-title>
     <div v-if="error == '' && sent == false">
        <v-card-text> Awaiting Keplr... </v-card-text>
        <v-card-text>
          Set the amount of gas or the fee you are willing to pay
        </v-card-text>
        <v-divider></v-divider></div>
        <v-alert dense class="ma-2" type="warning" v-if="error"
          >The transaction result is: {{ error.rawLog }}</v-alert
        >
        <v-alert dense class="ma-2" type="success" v-else-if="sent"
          >The transaction is sent</v-alert
        >
        <v-card-actions>
          <v-btn text @click="$emit('clicked', false)"> Discard </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

    
<script>
import {
  SigningStargateClient,
  assertIsBroadcastTxSuccess,
  isBroadcastTxSuccess,
} from "@cosmjs/stargate";
import { Registry } from "@cosmjs/proto-signing/";
import { Random } from "@cosmjs/crypto/";
import { Type, Field } from "protobufjs";
const miscreant = require("miscreant");
import { sharedKey as x25519, generateKeyPair } from "curve25519-js";
import { toUtf8, fromBase64 } from "@iov/encoding";
//import { encode } from "@js-base64";

//import secureRandom from "secure-random";
//const secureRandom = require("secure-random");

//import axios from "axios";
const hkdf = require("js-crypto-hkdf");

const cryptoProvider = new miscreant.PolyfillCryptoProvider();

export default {
  props: {
    fields: Array,
    value: Object,
    msg: String,
    typeUrl: String,
    // random: Uint8Array,
    // nonce: Uint8Array,

    //encryptedMsg: String,
  },

  //  props: ["type", "fields", "value", "msg"],
  data() {
    return {
      amount: 0,
      dialog: true,
      sent: false,
      error: "",
      encrypted: "",
      gas: 200000,
      wallet: {},
      keplr: null,
      //seed: Uint8Array,
      rules: {
        price: (value) => value > -1 || "Must be positive",
      },
    };
  },

  mounted() {
    this.$store.dispatch("setSortedTagList");

    this.$store.dispatch("setCodeHash", 1);
    this.$store.dispatch("setCodeHash", 2);
    this.$store.dispatch("setMasterKey");

    this.wallet = this.$store.state.wallet;
    this.keplr = this.$store.state.keplr;

    if (this.keplr) {
      this.getKeplr();
      //this.submit(this.wallet)
    }
  },

  methods: {
    async getKeplr() {
      let keplrsigner = await window.getOfflineSignerAuto("trst_chain_1");

      this.submit(keplrsigner);
    },

    async submit(wallet) {
      console.log("submitting");

      if (this.typeUrl) {
        var typeUrl = this.typeUrl;

        var client = await SigningStargateClient.connectWithSigner(
          process.env.VUE_APP_RPC,
          wallet
        );
      } else {
        var typeUrl = `/trstlabs.trst.trst.` + this.msg;

        let MsgCreate = new Type(`${this.msg}`);
        const registry = new Registry([[typeUrl, MsgCreate]]);
        this.fields.forEach((f) => {
          MsgCreate = MsgCreate.add(new Field(f[0], f[1], f[2], f[3]));
        });
        var client = await SigningStargateClient.connectWithSigner(
          process.env.VUE_APP_RPC,
          wallet,
          { registry }
        );
      }
      //console.log("TEST" + client)
      const msg = {
        typeUrl,
        value: this.value,
      };

      console.log(msg);
      const fee = {
        amount: [{ amount: this.amount.toString(), denom: "utrst" }],
        gas: this.gas.toString(),
      };
      console.log(client);
      console.log(this.$store.state.account.address);
      const result = await client.signAndBroadcast(
        this.$store.state.account.address,
        [msg],
        fee
      );

      assertIsBroadcastTxSuccess(result);
      this.sent = true;
      console.log("success!");
      if (isBroadcastTxSuccess(result)) {
        setTimeout(() => this.$emit("clicked", true), 2000);
      } else {
        this.error = result;
      }

      //passs whether it is success or falure back to any parent component
    },
    /*
  async getTxEncryptionKey(txSenderPrivKey, nonce) {
    const ioExchPubkey = await this.$store.getters.getMasterKey;
        console.log(ioExchPubkey);
  //  let consensusIoPubKey = fromBase64(ioExchPubkey);
  //let consensusIoPubKey = Uint8Array
    // consensusIoPubKey = '21 185 166 251 233 193 0 18 199 108 198 88 151 71 165 142 58 104 137 10 192 190 39 232 147 62 102 58 43 173 184 57'
    var consensusIoPubKey = new Uint8Array(32);
consensusIoPubKey = Uint8Array([21,185,166,251,233,193,0,18,199,108,198,88,151,71,165,142,58,104,137,10,192,190,39,232,147,62,102,58,43,173,184,57]);

   console.log(txSenderPrivKey)
      console.log(Uint8Array.from(txSenderPrivKey))
    console.log(consensusIoPubKey);


const hkdfSalt = new Uint8Array.from([
  0x00,
  0x00,
  0x00,
  0x00,
  0x00,
  0x00,
  0x00,
  0x00,
  0x00,
  0x02,
  0x4b,
  0xea,
  0xd8,
  0xdf,
  0x69,
  0x99,
  0x08,
  0x52,
  0xc2,
  0x02,
  0xdb,
  0x0e,
  0x00,
  0x97,
  0xc1,
  0xa1,
  0x2e,
  0xa6,
  0x37,
  0xd7,
  0xe9,
  0x6d,
]);
    // let ioExchPubkey = fromBase64(consensusIoPubKey);
  //  console.log( Uint8Array.from(consensusIoPubKey))
    const txEncryptionIkm = x25519(Uint8Array.from(txSenderPrivKey), consensusIoPubKey);
    const { key: txEncryptionKey } = await hkdf.compute(
      Uint8Array.from([...txEncryptionIkm, ...nonce]),
      "SHA-256",
      32,
      "",
      hkdfSalt
    );
    return txEncryptionKey;
  },

  async encryptMsg(contractCodeHash, msg) {

  //  let contractCodeHash = this.$store.getters.getTagListgetCodeHash
  //    let   msg =   this.value.init_msg
  //  const nonce = this.nonce;
 console.log(this.random)
    console.log("encrypt start");

    const { privkey, pubkey } = this.GenerateNewKeyPairFromSeed(this.random);
    this.privkey = privkey;
    this.pubkey = pubkey;
         console.log(this.privkey)

    const txEncryptionKey = await this.getTxEncryptionKey(this.privkey, this.nonce);
    console.log("txEncryptionKey");
    const siv = await miscreant.SIV.importKey(
      txEncryptionKey,
      "AES-SIV",
      cryptoProvider
    );
    console.log("plaintext");
    const plaintext = toUtf8(contractCodeHash + JSON.stringify(msg));

    const ciphertext = await siv.seal(plaintext, [new Uint8Array()]);
    console.log("ciphertext");
    // ciphertext = nonce(32) || wallet_pubkey(32) || ciphertext
    this.encryptMsged = Uint8Array.from([...nonce, ...this.pubkey, ...ciphertext]);
  },
 

   GenerateNewSeed() {

     //this.seed = secureRandom(32, { type: "Uint8Array" });
   //   console.log(seed)
  // var secureRandom = require('secure-random')
//var data = secureRandom.randomUint8Array(32)
 this.seed = secureRandom.randomUint8Array(32)
   // return data;
   },

  GenerateNewKeyPairFromSeed(seed) {
 // let constr = new Uint8Array.from(seed);
    const {  public: pubkey, private: privkey} = generateKeyPair(seed);
    return { privkey, pubkey };
  }*/
  },
};
</script>